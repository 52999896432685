<template>
  <div class="food-truck-grid">
    <div v-for="truck in foodTrucks" :key="truck.id" class="food-truck-tile">
      <img :src="truck.picture" alt="Image of food truck" />
      <div class="food-truck-info">
        <h4>{{ truck.name }}</h4>
        <p class="address">{{ truck.address }}</p>
        <p class="type">{{ truck.typeOfFood }}</p>
        <p class="description">{{ truck.description }}</p>
        <div class="links">
          <a v-if="truck.menu" :href="truck.menu" target="_blank">Menu</a>
          <a v-if="truck.orderOnline" :href="truck.orderOnline" target="_blank"
            >Order Online</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

// Define the interface for a food truck
interface FoodTruck {
  id: number;
  name: string;
  picture: string;
  address: string;
  gps: [number, number];
  typeOfFood: string;
  description: string;
  menu?: string;
  orderOnline?: string;
}

export default defineComponent({
  name: "FoodTruckGrid",
  props: {
    foodTrucks: {
      type: Array as PropType<FoodTruck[]>,
      default: () => [],
    },
  },
  emits: ["centerOnTruck"],
});
</script>
<style scoped>
.food-truck-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.food-truck-tile {
  cursor: pointer;
  text-align: center;
  background: white;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  overflow: hidden;
}

.food-truck-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.food-truck-tile img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 15px;
  border-radius: 8px;
}

.food-truck-info {
  text-align: left;
}

.food-truck-info h4 {
  margin: 0;
  color: #333;
  font-size: 18px;
  font-weight: bold;
}

.food-truck-info p {
  margin: 5px 0;
  color: #666;
  font-size: 14px;
}

.address {
  font-style: italic;
}

.type {
  font-weight: bold;
  color: #444;
}

.description {
  margin-bottom: 10px;
}

.links a {
  display: inline-block;
  margin-right: 10px;
  background: #007bff;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.links a:hover {
  background: #0056b3;
}
</style>
