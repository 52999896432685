import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f321811"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "food-truck-grid" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "food-truck-info" }
const _hoisted_4 = { class: "address" }
const _hoisted_5 = { class: "type" }
const _hoisted_6 = { class: "description" }
const _hoisted_7 = { class: "links" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.foodTrucks, (truck) => {
      return (_openBlock(), _createElementBlock("div", {
        key: truck.id,
        class: "food-truck-tile"
      }, [
        _createElementVNode("img", {
          src: truck.picture,
          alt: "Image of food truck"
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h4", null, _toDisplayString(truck.name), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(truck.address), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(truck.typeOfFood), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(truck.description), 1),
          _createElementVNode("div", _hoisted_7, [
            (truck.menu)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: truck.menu,
                  target: "_blank"
                }, "Menu", 8, _hoisted_8))
              : _createCommentVNode("", true),
            (truck.orderOnline)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: truck.orderOnline,
                  target: "_blank"
                }, "Order Online", 8, _hoisted_9))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    }), 128))
  ]))
}