import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Ocala Food Trucks",
      metaTags: [
        {
          name: "description",
          content:
            "Explore the best food trucks in Ocala! Check out menus, photos, and find your favorite food truck cuisine, including American, Chinese, Jamaican, and more.",
          id: "desc",
        },
        {
          property: "og:title",
          content: "Ocala Food Trucks - Find the Best Food Trucks Here!",
        },
        {
          property: "og:description",
          content:
            "Explore the best food trucks in Ocala! Check out menus, photos, and find your favorite food truck cuisine, including American, Chinese, Jamaican, and more.",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:image",
          content: "https://i.ibb.co/cgmRqQy/ocalafo.webp",
        }, // Replace with an appropriate image URL
        { property: "og:url", content: "https://ocalafoodtrucks.com" }, // Replace with the actual URL
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
