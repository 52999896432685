<template>
  <div class="home">
    <h1>Ocala Food Trucks</h1>
    <FoodTruckGrid :foodTrucks="foodTrucks" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import FoodTruckGrid from "@/components/FoodTruckGrid.vue";
import VueHead from "vue-head";

interface FoodTruck {
  id: number;
  name: string;
  picture: string;
  address: string;
  gps: [number, number];
  typeOfFood: string;
  description: string;
  menu?: string;
  orderOnline?: string;
}

@Options({
  components: {
    FoodTruckGrid,
  },
})
export default class HomeView extends Vue {
  public foodTrucks: FoodTruck[] = [
    {
      id: 1,
      name: "Grateful Dogs",
      picture: "https://i.ibb.co/bzxzxbK/gfd.jpg",
      address: "6191 S Pine ave, Ocala, FL 34480",
      gps: [29.12539, -82.10161],
      typeOfFood: "American",
      description: "HotDogs , Burgers, Fries, and more!",
      menu: "https://www.facebook.com/photo/?fbid=122093123660220472&set=pb.61556614160341.-2207520000",
      orderOnline:
        "https://www.seamless.com/menu/grateful-dogs-4596-se-maricamp-rd-ocala/6611520",
    },
    {
      id: 2,
      name: "El Rincon Chino",
      picture: "https://i.ibb.co/vhs8phs/elricon.jpg",
      address: "2655 County Hwy 484, Ocala, FL 34473",
      gps: [29.02632, -82.16821],
      typeOfFood: "Chinese Puertorican",
      description: "Chinese Puertorican Food",
      menu: "https://www.facebook.com/elrinconchinopr",
    },
    {
      id: 3,
      name: "OD’s Jerk Grill",
      picture: "https://i.ibb.co/zxKwJ1B/jerk.jpg",
      address: "2655 County Hwy 484, Ocala, FL 34473",
      gps: [29.02632, -82.16821],
      typeOfFood: "Jamacian",
      description: "Jerk Chicken, Jerk Pork, Jerk Fish, and more!",
      menu: "https://i.ibb.co/P5Xdd4s/IMG-3796.jpg",
    },
    {
      id: 4,
      name: "La Isla del Frappé",
      picture: "https://i.ibb.co/0mf3vn5/islafrappe.jpg",
      address: "2655 County Hwy 484, Ocala, FL 34473",
      gps: [29.02632, -82.16821],
      typeOfFood: "Puertorican",
      description: "Icecream, dessets,Frappé, Puertorican Food",
      menu: "https://i.ibb.co/qgL99QS/IMG-3809.jpg",
    },
    {
      id: 4,
      name: "Taqueria Las Catrinas",
      picture: "https://i.ibb.co/L8gNZ89/IMG-3802.jpg",
      address: "2655 County Hwy 484, Ocala, FL 34473",
      gps: [29.02632, -82.16821],
      typeOfFood: "Mexican",
      description: "Tacos, Tortas, Burritos, and more!",
      menu: "https://i.ibb.co/XJ6zybB/IMG-3803.jpg",
    },
  ];
}
</script>
